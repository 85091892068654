import React, {Fragment} from "react";
import { Link } from 'gatsby';
import { useTranslation } from "react-i18next";
import { FaBolt } from 'react-icons/fa';
import { main, seeMore } from './corporate-responsibility.module.css';
import { resetLink, resetList, internalContainer } from '../../styles/utils.module.css';
import Layout from '../../components/Layout';
import InternalSection from '../../components/InternalSection';
import InternalSectionTitle from '../../components/InternalSectionTitle';
import InternalSidebar from '../../components/InternalSidebar';

export default () => {
    const { t } = useTranslation();

    const copy = t('aboutUs.corporateResponsibility', { returnObjects: true });

    return (
        <Layout>
            <InternalSection content={copy}>
                <div className={`${main} ${internalContainer}`}>
                    <div>
                        <InternalSectionTitle>{copy.mainContent.title}</InternalSectionTitle>
                        {copy.mainContent.segments.map((segment, index) => (
                            <Fragment key={index}>
                                {segment.paragraphs.map((p, index) => (
                                    <p key={index}>{p}</p>
                                ))}
                                <ul>
                                    {segment.bulletPoints.map((li, index) => (
                                        <li key={index}>{li}</li>
                                    ))}
                                </ul>
                            </Fragment>
                        ))}
                        <div className={`${resetList} ${seeMore}`}>
                            <h3>{copy.seeMore.title}</h3>
                            <p>{copy.seeMore.subtitle}</p>
                            <ul className={resetLink}>
                                {copy.seeMore.links.map((link, index) => (
                                    <li key={index}>
                                        <FaBolt size="1em" color="var(--lightYellow)"/>
                                        <Link to={link.url}>{link.label}</Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div>
                        <InternalSidebar />
                    </div>
                </div>
            </InternalSection>
        </Layout>
    );
}
